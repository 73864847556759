<template>
  <div>
    <Section>

      <div slot="contents">
        <v-layout row>
          <v-flex class="headline pb-2">
            Performance Statistics
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs6 class="body-1 pb-2">
            Date Performed: {{ datePerformed }}
            <br>
            Total Runtime: {{ runningTime }} (s)
          </v-flex>
        </v-layout>
      </div>

      <div slot="contents" class="text-md-center pt-2 pb-4">
        <v-layout row>
          <v-flex xs6 class="pr-2">
            <v-card
              color="primary"
              dark
            >
              <v-card-title primary-title>
                <div class="perf-center">
                  <h3 class="headline mb-0">Enroll Response Times (ms)</h3>
                  <div>
                    Average: {{ meanEnroll }}
                    <br>
                    Min: {{ minEnroll }}
                    <br>
                    Max: {{ maxEnroll }}
                    <br>
                    TPS: {{ enrollTps }}
                  </div>
                </div>
              </v-card-title>
            </v-card>
          </v-flex>
          <v-flex xs6 class="pl-2">
            <v-card
              color="accent"
              dark
            >
              <v-card-title primary-title>
                <div class="perf-center">
                  <h3 class="headline mb-0">Verify Response Times (ms)</h3>
                  <div>
                    Average: {{ meanVerify }}
                    <br>
                    Min: {{ minVerify }}
                    <br>
                    Max: {{ maxVerify }}
                    <br>
                    TPS: {{ verifyTps }}
                  </div>
                </div>
              </v-card-title>
            </v-card>
          </v-flex>
        </v-layout>
      </div>
    </Section>
  </div>
</template>

<style>
  .perf-center {
    width: 100%;
    text-align: center;
  }
</style>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'PerformanceStats',
  props: [
    'runningTime',
    'endTime',
  ],
  computed: {
    ...mapGetters('perf', {
      meanEnroll: 'meanEnroll',
      meanVerify: 'meanVerify',
      minEnroll: 'minEnroll',
      minVerify: 'minVerify',
      maxEnroll: 'maxEnroll',
      maxVerify: 'maxVerify',
      sumEnroll: 'sumEnroll',
      sumVerify: 'sumVerify',
    }),
    enrollTps() {
      return (1000 / this.meanEnroll).toFixed(2);
    },
    verifyTps() {
      return (1000 / this.meanVerify).toFixed(2);
    },
    datePerformed() {
      if (this.endTime) {
        return moment(this.endTime).format('MM/DD/YYYY HH:MM');
      }
      return '';
    },
  },
  methods: {
    moment() {
      return moment();
    },
  },
};
</script>
